export default {
    //  是否启用呼叫服务
    isEnabled() {
        let storage = sessionStorage.getItem('config');
        if (storage) {
            let config = JSON.parse(storage);
            return config.ctiServiceEnabled && config.callServiceEnabled ? true : false;
        } else {
            return false;
        }
    },
    //  是否禁用呼叫服务
    isDisabled() {
        return !this.isEnabled();
    },
};
