<template>
    <div class="headerc">
        <div class="k">
            <div class="left">
                <img :src="logoUrl" alt />
                <div class="menu-area">
                    <!--   -->
                    <div
                        v-for="item in menuList"
                        :key="item.name"
                        :style="{color: clickMenuObj.name == item.name ? '#4086EC' : '', 'font-weight': 'bold'}"
                        @click="clickMenu(item)"
                    >{{ item.name }}</div>
                </div>
            </div>
            <div class="right">
                <span @click="test" style="margin-right: 10px">测试</span>
                <span>欢迎登录</span>
                <span style="margin: 0 15px">{{ admin }}</span>
                <el-dropdown style="outline: none" @command="handleCommand">
                    <span style="outline: none;cursor:pointer" class="el-dropdown-link">更多</span>
                    <el-dropdown-menu slot="dropdown">
                        <el-dropdown-item command="a">修改密码</el-dropdown-item>
                        <el-dropdown-item command="b">退出</el-dropdown-item>
                    </el-dropdown-menu>
                </el-dropdown>
            </div>
        </div>
        <!-- 退出 -->
        <el-dialog title="提示" :visible.sync="dialogVisible" width="30%" append-to-body>
            <span>你确定要退出吗？</span>
            <span slot="footer" class="dialog-footer">
                <el-button type="primary" @click="logout()">确 定</el-button>
                <el-button @click="dialogVisible = false">取 消</el-button>
            </span>
        </el-dialog>
        <!-- 修改密码 -->
        <el-dialog
            v-if="dialogTableVisible"
            center
            title="修改密码"
            :visible.sync="dialogTableVisible"
            width="30%"
            append-to-body
        >
            <el-form :model="form">
                <el-form-item label="旧密码" :label-width="formLabelWidth">
                    <el-input
                        v-model="form.oldPassword"
                        onkeyup="value=value.replace(/[\u4e00-\u9fa5/\s+/]/ig,'')"
                        placeholder="请输入旧密码"
                        maxlength="32"
                        show-word-limit
                        show-password
                    />
                </el-form-item>
                <el-form-item label="新密码" :label-width="formLabelWidth">
                    <el-input
                        v-model="form.newPassword"
                        onkeyup="value=value.replace(/[\u4e00-\u9fa5/\s+/]/ig,'')"
                        placeholder="请输入新密码"
                        maxlength="32"
                        show-word-limit
                        show-password
                    />
                </el-form-item>
                <el-form-item label="确认密码" :label-width="formLabelWidth">
                    <el-input
                        v-model="form.confirmPassword"
                        onkeyup="value=value.replace(/[\u4e00-\u9fa5/\s+/]/ig,'')"
                        placeholder="请输入确认密码"
                        maxlength="32"
                        show-word-limit
                        show-password
                    />
                </el-form-item>
            </el-form>
            <div slot="footer" class="dialog-footer">
                <el-button type="primary" @click="submit">确 定</el-button>
                <el-button @click="dialogTableVisible = false">取 消</el-button>
            </div>
        </el-dialog>
    </div>
</template>
<script>
import { logout } from '../api/myclueApi.js';
import { password, menus } from '../api/public_content.js';
export default {
    props: {
        isHiddenMenu: [Boolean],
        menuItems: [Object],
    },
    data() {
        return {
            form: {
                oldPassword: '',
                newPassword: '',
                confirmPassword: '',
            },
            formLabelWidth: '70px',
            dialogTableVisible: false,
            dialogVisible: false,
            admin: '',
            logoUrl: '',
            devName: '',
            menuList: [],
            clickMenuObj: {},
            routerCode: '',
            routerIndex: '',
            findItem: [],
            vArr: [],
        };
    },
    watch: {
        $route: {
            handler(newRouter) {
                let linkPageCode = newRouter.path.substr(
                    1,
                    newRouter.path.length - 1
                );
                this.routerCode = linkPageCode;
            },
            immediate: true,
        },
        isHiddenMenu(val) {
            if (this.debugOpen)
                console.log('headerc.vue -> watch -> isHiddenMenu()', val);
            if (!val) {
                this.clickMenuObj = {};
            }
        },
        menuItems(val) {
            this.clickMenuObj = val;
        },
    },
    created() {
        if (this.debugOpen) console.log('headerc.vue -> created()');

        this.getMenuList();

        const logoUrl = sessionStorage.getItem('logoUrl');
        logoUrl
            ? (this.logoUrl = logoUrl)
            : require('@/assets/default_logo.png');

        // console.log("生命周期created", this.routerCode);
    },
    mounted() {
        if (this.debugOpen)
            console.log(
                'headerc.vue -> mounted()',
                'this.$store.state.sipRegister',
                this.$store.state.sipRegister
            );

        window.setTimeout(() => {
            this.admin = JSON.parse(
                window.sessionStorage.getItem('config')
            ).displayName;
        }, 1000);
        this.$EventBus.$on('loginName', this.loadLoginNameHandle);
    },
    BeforeDestroy() {
        if (this.debugOpen) console.log('headerc.vue -> BeforeDestroy()');
        //  删除监听器
        this.$EventBus.$off('loginName', this.loadLoginNameHandle);
    },
    methods: {
        // 获取头部菜单
        getMenuList() {
            menus().then((res) => {
                if (res.code == 0) {
                    this.menuList = res.data.list;

                    if (sessionStorage.getItem('menuItem')) {
                        this.clickMenuObj = JSON.parse(
                            sessionStorage.getItem('menuItem')
                        );
                    }
                    this.$emit('menuItem', this.clickMenuObj);
                    if (this.debugOpen)
                        console.log(
                            'headerc.vue -> methods -> getMenuList() -> menus()',
                            this.clickMenuObj
                        );

                    this.$emit('showAllClue', this.menuList);
                } else {
                    this.$message.error(res.error);
                }
            });
        },
        clickMenu(item) {
            if (this.debugOpen)
                console.log('headerc.vue -> methods -> clickMenu()', item);
            sessionStorage.setItem('menuItem', JSON.stringify(item));
            this.clickMenuObj = item;
            this.$emit('menuItem', item);
        },
        //确定修改密码
        submit() {
            if (this.form.oldPassword === '') {
                this.$message.error('请输入旧密码');
            } else if (this.form.newPassword === '') {
                this.$message.error('请输入新密码');
            } else if (this.form.newPassword.length < 6) {
                this.$message.error('新密码长度最少是6位');
            } else if (this.form.confirmPassword === '') {
                this.$message.error('请输入确认密码');
            } else if (this.form.confirmPassword !== this.form.newPassword) {
                this.$message.error('新密码与确认密码不一致');
            } else {
                password(this.form).then((res) => {
                    if (res.code == '0') {
                        this.$message({
                            message: '修改成功',
                            type: 'success',
                        });
                        (this.form = {
                            oldPassword: '',
                            newPassword: '',
                            confirmPassword: '',
                        }),
                            (this.dialogTableVisible = false);
                    } else {
                        this.$message.error(res.error);
                    }
                });
            }
        },
        //退出操作
        logout() {
            this.dialogVisible = false;
            if (this.debugOpen)
                console.log(
                    'headerc.vue -> methods -> logout()',
                    this.$store.state.timer
                );
            logout().then((res) => {
                if (res.code == 0) {
                    this.$message({
                        message: '退出成功',
                        type: 'success',
                    });
                    const prefix = sessionStorage.getItem('prefix');
                    window.localStorage.clear();
                    window.sessionStorage.clear();
                    this.admin = '';
                    window.clearInterval(this.$store.state.timer_1);
                    window.clearInterval(this.$store.state.timer_2);
                    this.$store.state.sipRegister = null;
                    // const softPhoneUA = this.$store.state.callUA
                    // if (softPhoneUA instanceof Object) {
                    //     softPhoneUA.unregister()
                    //     softPhoneUA.stop({
                    //       register: true
                    //     })
                    // }
                    this.$router.push({
                        path: '/login',
                        query: {
                            prefix: prefix,
                        },
                    });
                } else {
                    this.$message.error(res.error);
                }
            });
        },
        //返回主页
        zhuye() {
            if (this.$route.path != '/home') {
                this.$router.push({
                    path: '/home',
                });
            }
        },
        //下拉控件
        handleCommand(command) {
            if (command == 'a') {
                this.dialogTableVisible = true;
            } else if (command == 'b') {
                this.dialogVisible = true;
            }
        },
        //  加载登陆名事件处理
        loadLoginNameHandle(val) {
            this.admin = val;
        },
        test() {
            console.error('test');
            this.$router.push('test');
        }
    },
};
</script>
<style lang="less" scoped>
.headerc {
    height: 60px;
    width: 100%;
    background: #f6f6f6;
    box-shadow: 0px 0px 30px 0px rgba(70, 70, 70, 0.2);
    opacity: 0.96;
    overflow: hidden;

    .k {
        display: flex;
        justify-content: space-between;
        align-items: center;
        position: relative;
        // width: 100%;
        height: 60px;
        overflow: hidden;
        padding-right: 32px;

        .left {
            width: 180px;
            // display: inline-block;
            display: flex;
            text-align: center;

            img {
                margin-left: 10px;
                width: 118px;
                height: 41px;
            }

            .menu-area {
                margin-left: 75px;
                display: flex;
                font-size: 14px;

                div {
                    margin-left: 20px;
                    margin-right: 20px;
                    white-space: nowrap;
                    line-height: 41px;

                    &:hover {
                        cursor: pointer;
                    }
                }
            }
        }

        img {
            height: 100%;
            vertical-align: middle;
        }

        i {
            font-style: normal;
            color: #4086ec;
            display: inline-block;
            margin-left: 20px;
            cursor: pointer;
        }
    }
}
</style>
