import Vue from 'vue';
import Router from 'vue-router';
import Layout from '../views/layout.vue';
Vue.use(Router);
const originalPush = Router.prototype.push;
Router.prototype.push = function push(location) {
    return originalPush.call(this, location).catch((err) => err);
};
const Routes = [
    {
        name: 'test',
        path: '/test',
        component: () => import('@/components/test.vue'),
    },
    {
        path: '/login',
        name: 'login',
        component: () => import('@/components/login.vue'),
    },
    {
        path: '/401',
        name: '401',
        component: () => import('../components/401.vue'),
    },
    {
        path: '/404',
        name: '404',
        component: () => import('../components/404.vue'),
    },
    {
        path: '*', // 此处需特别注意置于最底部
        redirect: '/404',
    },
    {
        path: '/',
        name: 'home',
        redirect: '/home',
        component: Layout, // 导入组件 Layout
        hidden: true,
        children: [
            {
                path: '/home',
                component: () => import('@/views/home.vue'),
            },
            {
                path: '/my-clue',
                component: () => import('@/views/clue/myclue.vue'),
            },
            {
                path: '/public-clue',
                component: () => import('@/views/clue/seaclue.vue'),
            },
            {
                path: '/my-client',
                component: () => import('@/views/client/myclient.vue'),
            },
            {
                path: '/team-client',
                component: () => import('@/views/client/teamclient.vue'),
            },
            {
                path: '/public-client',
                component: () => import('@/views/client/seaclient.vue'),
            },
            {
                path: '/role',
                component: () => import('@/views/administrative/quanxian.vue'),
            },
            {
                path: '/personnel',
                name: 'personnel',
                component: () => import('@/views/administrative/personnel.vue'),
            },
            {
                path: '/system-setting',
                component: () => import('@/views/setup/system.vue'),
            },
            {
                path: '/attendance-setting',
                component: () => import('@/views/setup/kaoqin.vue'),
            },
            {
                path: '/test',
                component: () => import('@/views/public_components/label_selection.vue'),
            },
            {
                path: '/search',
                component: () => import('@/views/search/index.vue'),
            },
            {
                path: '/clue-records',
                component: () => import('@/views/clue/clue-records.vue'),
            },
            {
                path: '/clue-all',
                component: () => import('@/views/clue/clue-all.vue'),
            },
            {
                path: '/style-setting',
                component: () => import('@/views/setup/style-setting.vue'),
            },
            // 这个做测试
            {
                path: '/import-clue',
                component: () => import('@/views/dataManage/importClue'),
            },
            {
                path: '/import-client',
                component: () => import('@/views/dataManage/importClient'),
            },
            {
                path: '/call-chart',
                component: () => import('@/views/dataManage/callAnalysis'),
            },
            {
                path: '/call-record',
                component: () => import('@/views/dataManage/callRecord'),
            },
            {
                path: '/call-tops',
                component: () => import('@/views/dataManage/callLevel'),
            },
            {
                path: '/call-setting',
                component: () => import('@/views/setup/callSetting'),
            },
            {
                path: '/turns-call-list',
                component: () => import('@/views/turnsCallList/turnsCallList.vue'),
                props: (data) => {
                    return {
                        pageType: data.query.pageType,
                        type: data.query.type,
                        previousPath: data.query.previousPath,
                        size: data.query.size,
                    };
                },
            },
            {
                path: '/invalid-clue',
                component: () => import('@/views/clue/invalid-clue'),
            },
            {
                path: '/invalid-client',
                component: () => import('@/views/client/invalid-client'),
            },
        ],
    },
];

const createRouter = () =>
    new Router({
        routes: Routes,
    });
const router = createRouter();

export default router;
