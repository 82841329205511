<template>
    <div>
        <!-- 顶部栏 -->
        <headerc
            :menu-items="menuItems"
            :is-hidden-menu="isHiddenMenu"
            @menuItem="menuItem"
            @showAllClue="showAllClue"
        />
        <!-- 内容 -->
        <div class="boxbody-box">
            <!-- 左侧的菜单栏 -->
            <sidebar v-show="isHiddenMenu" :menu-items="menuItems" />

            <!-- 右侧的主体内容 -->
            <div class="right">
                <router-view ref="rightView" />
                <!-- 新线索的图标 -->
                <!-- <div class="newclue" @click="linkNew">
          <div class="k">
            <span
              ><i>{{ num }}</i
              >新线索</span
            >
          </div>
                </div>-->
                <!-- 修改版的新线索图标 -->
                <div
                    id="dragbtn"
                    v-drag
                    style="position: fixed;right: 15px;top: 55%;z-index: 1200;"
                >
                    <!-- 未接来电 -->
                    <el-badge
                        v-if="callServices.isEnabled()"
                        :value="missedCall.length"
                        class="item"
                    >
                        <div class="search" style="margin-bottom: 10px" @click="changeMissedCall">
                            <div class="missed-call" />
                        </div>
                        <transition name="el-fade-in-linear">
                            <div v-show="isMissedCallHint" class="bubble missed-call-hint">
                                <div class="message">
                                    <img src="@/assets/common/notice.png" alt />
                                    <div>有新的未接来电，请及时跟进</div>
                                    <i class="el-icon-close" @click="changeMissedCallHint" />
                                </div>
                            </div>
                        </transition>
                    </el-badge>

                    <!-- 未接来电弹窗 -->
                    <div v-show="isMissedCall" class="missed-call-dialog">
                        <div class="missed-call-dialog-title">未接列表</div>
                        <div
                            class="el-icon-close missed-call-dialog-icon"
                            @click="changeMissedCall"
                        />
                        <div class="missed-call-dialog-list">
                            <div
                                v-for="(item, index) in missedText"
                                :key="index"
                                class="missed-call-dialog-list-item"
                            >
                                <div class="head">
                                    <div>{{ item.calledTime | getYMDHMS }}</div>
                                    <div @click="addNewClient(item)">{{ item.btn }}</div>
                                </div>
                                <div class="body">
                                    <div>
                                        {{ item.name }}
                                        <span>
                                            <!-- {{ item.phoneNum }} -->
                                            {{ common.hidePhoneNumber(item.phoneNum) }}
                                        </span>
                                    </div>
                                </div>
                                <div class="feet">{{ item.message }}</div>
                                <el-button
                                    id="clearBtn"
                                    type="info"
                                    size="mini"
                                    icon="el-icon-delete"
                                    circle
                                    @click="clearBtn(item.phoneNum)"
                                />
                            </div>
                        </div>
                    </div>

                    <div v-show="Dial_Change" class="call-dial" style="background-color: #ffffff">
                        <div class="call-dial-close">
                            <div class="el-icon-close" @click="toCallPhone" />
                        </div>
                        <div class="dial-input">
                            <input
                                id="dial-InputText"
                                v-model="dialNumber"
                                type="text"
                                name
                                placeholder="请输入客户手机号"
                                class="input-dial-style"
                            />
                            <!-- <span>{{dialNumber}}</span> -->
                        </div>
                        <div class="dial-keybord">
                            <div v-for="(item, index) in telButtonNumber" :key="index">
                                <span @click="buttonCall(index)">{{ item.itemValue }}</span>
                            </div>
                        </div>
                        <div
                            v-if="callServices.isEnabled()"
                            class="call-button"
                            @click="callStart()"
                        >
                            <img
                                src="../assets/common/call-phone/callPhone.png"
                                style="width: 20px; height: 20px"
                            />
                        </div>
                    </div>

                    <div v-show="callOnChange" class="call-dial-onPhone">
                        <div class="onPhone-change">
                            <img
                                src="../assets/common/call_slices2/call-logo.png"
                                class="img-onPhone-sec"
                            />
                            <!-- <span v-text="callOnPhoneChange"></span> -->
                            <!-- <span>{{callOnPhoneChange}}--{{calljOnPhoneChange}}</span> -->
                            <span>{{ callOnPhoneChange }}</span>
                        </div>
                        <img
                            v-show="callOnChange_1"
                            src="../assets/common/call-out/call-out2x.png"
                            class="img-onPhone call-out"
                            style="margin-right: 10px"
                            @click="callOut()"
                        />
                    </div>

                    <div v-if="callServices.isEnabled()" @click="toCallPhone">
                        <div class="search">
                            <div class="call-phone" />
                        </div>
                    </div>
                    <div @click="toSearch">
                        <div class="search" style="margin-top: 10px">
                            <!-- <embed src="../assets/common/search.png" width="100%" height="100%"> -->
                            <!-- <img src="@/assets/common/search.png"alt="" style="margin-top:13px"/> -->
                            <div class="search-icon" />
                        </div>
                    </div>
                    <!-- 气泡框 -->
                    <transition name="el-fade-in-linear">
                        <div v-show="getNewClue" class="bubble">
                            <div class="message">
                                <img src="@/assets/common/notice.png" alt />
                                <div>
                                    收到一条新线索，请前往
                                    <span
                                        style="color: #4086ec"
                                        @click="toNewCluePage"
                                    >【线索池】</span>
                                    联系
                                </div>
                                <i class="el-icon-close" @click="closeNewTip" />
                            </div>
                        </div>
                    </transition>
                    <div style="margin-top: 10px" @click="dialogVisible = true">
                        <el-badge>
                            <div class="newClue">
                                <div>问题</div>
                                <div>反馈</div>
                            </div>
                        </el-badge>
                    </div>

                    <div v-if="findAllClue" style="margin-top: 10px" @click="linkNew">
                        <el-badge :value="clueNum.newClueCount" class="item">
                            <div class="newClue">
                                <div>全部</div>
                                <div>{{ clueNum.total }}条</div>
                            </div>
                        </el-badge>
                    </div>
                </div>
            </div>
            <el-dialog
                destroy-on-close
                title="问题反馈"
                :visible.sync="dialogVisible"
                width="30%"
                :close-on-click-modal="true"
                :center="true"
            >
                <el-form ref="question" :model="ruleForm" :rules="rules" class="feedbackForm">
                    <el-form-item prop="desc">
                        <el-input
                            v-model="ruleForm.desc"
                            type="textarea"
                            maxlength="255"
                            :rows="7"
                            placeholder="请您留下宝贵意见~"
                        >></el-input>
                    </el-form-item>
                </el-form>
                <div style="text-align: center">
                    <el-button type="primary" @click="submitForm">提交</el-button>
                </div>
            </el-dialog>
        </div>
        <audio id="remoteAudio" ref="getaudio" />
        <!-- <audio id="localAudio"></audio> -->
        <!-- 是否接听弹窗 -->
        <el-dialog
            custom-class="tipsDialog"
            title="来电提醒"
            width="300px"
            :visible="showAnswerDialog"
            :show-close="false"
        >
            <div>手机号码为{{ callOnChanges }}的客户来电，是否进行接听</div>
            <span slot="footer" class="dialog-footer">
                <el-button type="primary" @click="answerThePhone">接听</el-button>
                <el-button type="primary" plain @click="rejectCall">拒绝</el-button>
            </span>
        </el-dialog>
        <!-- <add-client-dialog ref="addClient" :show-dialog.sync="showAddDialog" /> -->
        <restore-dialog
            :is-show.sync="restoreDialog"
            :optional="optional"
            :title="titlt"
            @confirm="confirm"
        />
        <clue-info-dialog ref="clueShowDialog" @recover="recovers" />
        <client-info-dialog ref="clientShowDialog" @recover="recovers" />
    </div>
</template>
<script>
import sidebar from './sidebar';
import headerc from './headerc';
import layout from '@/mixins/layout';
import { clueData, askQuestion } from '@/api/public_content';
import { getCallState, getMissedList } from '@/api/callSetting';
import callServices from '@/assets/js/callServices';
let currentSession = null;
export default {
    mixins: [layout],
    provide() {
        return {
            refreshClueData: this.getClueData,
            callPhonechange: this.callOtherStart,
            callClientChange: this.callOtherStart,
        };
    },
    data() {
        return {
            callOutChange: true, //	挂断按钮隐藏
            Dial_Change: false, //	电话拨号盘开启
            dialNumber: '',
            callOnChange: false,
            callOnChange_1: false,
            callOnPhoneChange: '',
            calljOnPhoneChange: '',
            telButtonNumber: [
                {
                    itemValue: 1,
                },
                {
                    itemValue: 2,
                },
                {
                    itemValue: 3,
                },
                {
                    itemValue: 4,
                },
                {
                    itemValue: 5,
                },
                {
                    itemValue: 6,
                },
                {
                    itemValue: 7,
                },
                {
                    itemValue: 8,
                },
                {
                    itemValue: 9,
                },
                {
                    itemValue: '*',
                },
                {
                    itemValue: 0,
                },
                {
                    itemValue: '#',
                },
            ],
            // num: this.$store.state.num,
            // newVal:sessionStorage.getItem('newVal')
            // numVal: 0,
            ruleForm: {
                desc: '',
            },
            rules: {
                desc: [
                    // { message: "请输入反馈内容", trigger: "blur", required: true, },
                    {
                        min: 6,
                        max: 255,
                        message: '请输入不少于6个字符内容',
                        trigger: 'blur',
                        required: true,
                    },
                ],
            },

            dialogVisible: false,
            visible: false,
            getNewClue: false,
            clueNum: {
                newClueCount: '',
                total: '',
            },
            websocket: null,
            lockReconnect: false, //是否真正建立连接
            timeout: 9 * 60 * 1000, //30秒一次心跳
            // timeout: 5 * 1000, //30秒一次心跳
            timeoutObj: null, //心跳心跳倒计时
            serverTimeoutObj: null, //心跳倒计时
            timeoutnum: null, //断开 重连倒计时
            loading: true,
            // allValue: [],
            menuItems: {},
            admin: '',
            content: '',
            isHiddenMenu: true,
            findAllClue: false,
            menuList: [],
            softPhoneUA: '',
            callTi: '',
            timer: null,
            // 是否显示未接来电列表
            isMissedCall: false,
            // 添加用户弹窗
            showAddDialog: false,
            isMissedCallHint: false,
            showAnswerDialog: false,
            missedCall: [],
            invitation: null,
            callOnChanges: '',
        };
    },
    components: {
        sidebar,
        headerc,
        // addClientDialog: () =>
        //     import('./client/components/addClientDialog.vue'),
    },
    created() {
        if (this.debugOpen) console.log('layout.vue -> created()');
        this.getMissedList();
        this.newWebSocket();
        this.getClueData();
    },
    mounted() {
        if (this.debugOpen) console.log('layout.vue -> mounted()');
        if (callServices.isEnabled()) {
            this.sipRE();
            this.$EventBus.$on('sipRegister', this.sipRE);
        }
    },
    beforeDestroy() {
        if (this.debugOpen) console.log('layout.vue -> beforeDestroy()');
        this.$EventBus.$off('sipRegister', this.sipRE);
        this.$EventBus.$off('LoginSIP', this.loginSIPHandle);
        this.$EventBus.$off('getLoginOut', this.logoutHandle);
    },
    computed: {
        num: {
            // return this.$store.state.num
            get() {
                return this.$store.state.num;
            },
            set(v) {
                this.countData = v;
            },
        },
        missedText() {
            let list = [];
            this.missedCall.forEach((item) => {
                let items = {
                    ...item,
                    ...this.judgeText(item.type),
                };
                list.push(items);
            });
            return list;
        },
    },
    watch: {
        $route(to, from) {
            this.getNewClue = false;
        },
        clueNum(val) {},
    },
    methods: {
        // 过滤未接来电文本
        judgeText(type) {
            let text = {};
            if (type === 0) {
                text.btn = '新增为客户';
                text.message = '未知来电未接听，请及时跟进';
            } else if (type === 1 || type === 3) {
                text.btn = '跟进线索';
                text.message = '线索来电未接听，请及时跟进';
            } else if (type === 2 || type === 4) {
                text.btn = '跟进客户';
                text.message = '客户来电未接听，请及时跟进';
            }
            return text;
        },
        // 获取未接列表
        getMissedList() {
            getMissedList().then((res) => {
                this.missedCall = res.data.list;
            });
        },
        // 控制是否显示未接来电列表
        changeMissedCall() {
            this.isMissedCall = !this.isMissedCall;
            this.Dial_Change = false;
            this.isMissedCallHint = false;
        },
        changeMissedCallHint() {
            this.isMissedCallHint = false;
        },
        // 接听电话
        answerThePhone() {
            this.showAnswerDialog = false;
            this.invitation.accept({
                media: {
                    constraints: {
                        audio: true,
                        video: false,
                    },
                    render: {
                        remote: document.getElementById('remoteAudio'),
                    },
                },
                extraHeaders: [
                    'X-clueId:null',
                    'X-clientId:null',
                    'X-callUUID:null',
                    'X-token:null',
                    'X-calledName:null',
                ],
            });
            this.timer = window.setInterval((item) => {
                this.$EventBus.$emit('CallEventName_1', true);
                this.$EventBus.$emit('CallEventName_2', true);
            }, 1000);
            // 更改呼叫状态
            this.$store.commit('changePhoneState', true);
            this.callOnChange = true;
            this.callOnChange_1 = true;
            this.callOnPhoneChange = '呼叫中...';
        },
        rejectCall() {
            this.showAnswerDialog = false;
            this.invitation.reject();
        },

        sipRE() {
            /*
					下面这一坨代码，如果你不会请不要乱动，这并不是我写的，我只是为它包装了一层糖衣
					因为我也是第一次见到这样乱的代码
					就当长见识了
					我还想吐槽一下，用组件代替路由用，这是我从未见过的设计模式，没点病都不会这样写
				*/

            console.log('sip注册');
            //sip注册
            // 我这里这么写是为了处理第一次登录和页面刷新的时候的情况，
            // 但我不知道拿什么值来判断所以拿vuex存入的账号名判断null了,写法有点臃肿,但是起码效果没问题
            if (this.$store.state.sipRegister == null) {
                this.$EventBus.$on('LoginSIP', this.loginSIPHandle);
            } else {
                let sipOtherReg = this.$store.state.sipRegister;
                // console.log(sipOtherReg, 'sipOtherReg')
                this.softPhoneUA = new SIP.UA(sipOtherReg);
                this.$store.commit('login_calllUA', this.softPhoneUA);
                this.UAEvent(this.softPhoneUA);
                this.softPhoneUA.register({
                    // 注册
                    register: true,
                });
            }
        },
        UAEvent(ua) {
            // 开始尝试连接
            ua.on('connecting', function (args) {
                console.log('%c connecting', 'color: #f00');
            });
            // 连接完毕
            ua.on('connected', function () {
                console.log('%c connected', 'color: #f00');
            });
            // 主动取消注册或注册后定期重新注册失败
            ua.on('unregistered', function (response, cause) {
                console.log('%c unregistered', 'color: #f00');
            });
            // 注册成功
            ua.on('registered', function () {
                console.log('%c registered', 'color: #f00');
            });
            // websocket 连接失败
            ua.on('disconnected', function () {
                console.log('%c disconnected', 'color: #f00');
            });
            ua.on('invite', (data) => {
                if (this.$store.state.isBeingPhone) {
                    return data.reject();
                }
                data.on('cancel', (item) => {
                    this.showAnswerDialog = false;
                });
                data.on('bye', (item) => {
                    this.showAnswerDialog = false;

                    this.callOutChange = false;
                    let calljTimeOutc = '';
                    window.clearInterval(this.timer);
                    setTimeout(() => {
                        this.$EventBus.$emit('CallEventName_1', false);
                        this.$EventBus.$emit('CallEventName_2', false);
                    }, 1200);
                    // 更改呼叫状态
                    this.$store.commit('changePhoneState', false);
                    // localStorage.setItem('phoneStatus_Change', false)
                    this.callOnPhoneChange = '通话结束';
                    this.callOnChange_1 = false;
                    calljTimeOutc = setInterval(() => {
                        this.callOnChange = false;
                        clearInterval(calljTimeOutc);
                    }, 1000);
                });
                this.invitation = data;
                const str = data.request.data;
                this.callOnChanges = str.substring(
                    str.indexOf('From: "') + 7,
                    str.indexOf('From') + 18
                );
                this.showAnswerDialog = true;
                console.log('-----有电话呼入-------');
            });
        },

        // 拨号盘开关
        toCallPhone() {
            this.isMissedCall = false;
            if (this.callOnChange) {
                this.Dial_Change = false;
            } else {
                if (this.Dial_Change == false) {
                    this.Dial_Change = true;
                } else {
                    this.Dial_Change = false;
                }
            }
        },

        buttonCall(indexa) {
            this.dialNumber += this.telButtonNumber[indexa].itemValue;
        },

        // 外部通话接听
        callOtherStart() {
            this.getCallState(this.$store.state.callStatus.phoneNumber, 0);
        },
        // 处理异步
        callOtherStart_1() {
            let getThisDataStatus = {
                getBoolean: true,
            };
            localStorage.setItem(
                'phoneStatus_Change',
                JSON.stringify(getThisDataStatus)
            );
            console.log(getThisDataStatus);

            this.timer = window.setInterval((item) => {
                this.$EventBus.$emit('CallEventName_1', true);
                this.$EventBus.$emit('CallEventName_2', true);
            }, 1000);
            // 更改呼叫状态
            this.$store.commit('changePhoneState', true);
            this.callOnChange = true;
            this.callOnChange_1 = true;
            this.callOnPhoneChange = '呼叫中...';
            this.callPhoneSataus(this.$store.state.callStatus);
        },

        //通话接听过程
        callPhoneSataus(callState) {
            // console.log(this.$store.state.callUA, '1235667sPhoneUA')
            // console.log(this.$store.state.callStatus, 'callStatus')
            // let callState = this.$store.state.callStatus
            console.log(callState, 'callState');

            let fickPhone = '';
            fickPhone = this.$store.state.callUA;
            console.log(fickPhone, 'fickPhone');
            const telNumber = this.dialNumber;
            this.callTi = telNumber ? telNumber : callState.phoneNumber;

            currentSession = fickPhone.invite(this.callTi, {
                media: {
                    constraints: {
                        audio: true,
                        video: false,
                    },
                    render: {
                        // remote: this.$refs.getaudio.id,
                        remote: document.getElementById('remoteAudio'),
                    },
                },
                extraHeaders: [
                    'X-clueId:' + callState.clueId,
                    'X-clientId:' + callState.clientId,
                    'X-callUUID:' + callState.callUUID,
                    'X-token:' + callState.token,
                    'X-calledName:' + callState.calledName,
                ],
            });

            console.log(currentSession, 'currentSession_Phone');
            currentSession.on('rejected', (response, cause) => {
                console.log('%c rejected', 'color: #f00', 'reject');
                this.callOutChange = false;
                let calljTimeOutc = '';
                window.clearInterval(this.timer);
                setTimeout(() => {
                    this.$EventBus.$emit('CallEventName_1', false);
                    this.$EventBus.$emit('CallEventName_2', false);
                }, 1200);
                // 更改呼叫状态
                this.$store.commit('changePhoneState', false);
                // localStorage.setItem('phoneStatus_Change', false)
                this.callOnPhoneChange = '通话结束';
                this.callOnChange_1 = false;
                calljTimeOutc = setInterval(() => {
                    this.callOnChange = false;
                    clearInterval(calljTimeOutc);
                }, 1000);
            });
            currentSession.on('bye', (response, cause) => {
                // 本次通话结束
                console.log('%c bye', 'color: #f00');
                this.callOutChange = false;
                window.clearInterval(this.timer);
                setTimeout(() => {
                    this.$EventBus.$emit('CallEventName_1', false);
                    this.$EventBus.$emit('CallEventName_2', false);
                }, 1200);
                // 更改呼叫状态
                this.$store.commit('changePhoneState', false);
                // localStorage.setItem('phoneStatus_Change', false)
                this.callOnPhoneChange = '通话结束';
                if (response.method == 'BYE') {
                    let calljTimeOutd = '';
                    this.callOnChange_1 = false;
                    calljTimeOutd = setInterval(() => {
                        this.callOnChange = false;
                        clearInterval(calljTimeOutd);
                    }, 1000);
                }
            });

            currentSession.on('accepted', (response, cause) => {
                // 对方接听
                if (cause == 'OK') {
                    this.callOnPhoneChange = '接听中...';
                }
                console.log('%c accepted', 'color: #f00');
            });

            currentSession.on('cancel', (response, cause) => {
                // 取消通话
                console.log('%c cancel', 'color: #f00');
                let calljTimeOutce = '';
                window.clearInterval(this.timer);
                setTimeout(() => {
                    this.$EventBus.$emit('CallEventName_1', false);
                    this.$EventBus.$emit('CallEventName_2', false);
                }, 1200);
                // 更改呼叫状态
                this.$store.commit('changePhoneState', false);
                // localStorage.setItem('phoneStatus_Change', false)
                this.calljOnPhoneChange = '通话结束';
                this.callOnChange_1 = false;
                calljTimeOutce = setInterval(() => {
                    this.callOnChange = false;
                    clearInterval(calljTimeOutce);
                }, 1000);
            });
        },

        // 拨号盘打电话
        callStart() {
            this.getCallState(this.dialNumber, 1);
        },
        // 处理异步
        callStart_1() {
            let re = /^1\d{10}$/;
            if (re.test(this.dialNumber)) {
                // alert("正确");
                this.callOutChange = true;
                this.callOnChange = true;
                this.callOnChange_1 = true;
                this.Dial_Change = false;
                this.timer = setInterval(() => {
                    this.$EventBus.$emit('CallEventName_1', true);
                    this.$EventBus.$emit('CallEventName_2', true);
                }, 1000);
                // 更改呼叫状态
                this.$store.commit('changePhoneState', true);
                this.callOnPhoneChange = '呼叫中...';
                let getThisDataStatus = {
                    getBoolean: true,
                };
                localStorage.setItem(
                    'phoneStatus_Change',
                    JSON.stringify(getThisDataStatus)
                );
                this.callPhoneSataus({
                    phoneNumber: null,
                    clueId: null,
                    clientId: null,
                    callUUID: null,
                    // followSaved:'',
                    calledName: null,
                    token: null,
                });
            } else if (this.dialNumber == '') {
                alert('请输入电话号码');
            } else {
                alert('输入错误');
            }
        },

        // 接听状态
        // sessionEvent(session) {
        // 	session.on("rejected", function(response, cause) {
        // 		console.log('%c invite-rejected', 'color: #f00');
        // 	});
        // 	session.on("bye", function(response, cause) {
        // 		console.log('%c invite-bye', 'color: #f00');
        // 	});
        // 	session.on("accepted", function(response, cause) {
        // 		console.log('%c invite-accepted', 'color: #f00');
        // 		// this.callOnPhoneChange = '接听中...'
        // 	});
        // 	session.on("cancel", function(response, cause) {
        // 		console.log('%c invite-cancel', 'color: #f00');
        // 	});
        // },

        // 获取当前手机号状态状态（是否可以拨打）
        getCallState(phoneNumber, type) {
            getCallState(phoneNumber).then((res) => {
                if (res.code !== '0') return;
                if (res.data.callEnabled) {
                    type === 0 ? this.callOtherStart_1() : this.callStart_1();
                } else {
                    this.$message({
                        message: '客户号码已达到今日呼叫次数上限',
                        type: 'success',
                    });
                }
            });
        },

        // 挂断
        callOut() {
            this.callOnPhoneChange = '通话结束';
            this.callOutChange = false;
            this.dialNumber = '';
            if (this.callTi) this.callTi = null;
            console.log(this.callTi, '已清空');
            this.$EventBus.$on('getLoginOut', this.logoutHandle);
            window.clearInterval(this.timer);
            setTimeout(() => {
                this.$EventBus.$emit('CallEventName_1', false);
                this.$EventBus.$emit('CallEventName_2', false);
            }, 1200);
            // 更改呼叫状态
            this.$store.commit('changePhoneState', false);
            let getThisDataStatus = {
                getBoolean: false,
            };
            localStorage.setItem(
                'phoneStatus_Change',
                JSON.stringify(getThisDataStatus)
            );
            // this.callOnChange = false

            // 挂断传参,如需必要的话就把以下注释代码开启并把currentSession.bye()注释掉开下面那个currentSession.bye(options);
            // let callState = this.$store.state.callStatus
            // let extraHeaders = ['X-clueId:' + callState.clueId, 'X-clientId:' + callState.clientId,
            // 	'X-followSaved:1', 'X-callUUID:' + callState.callUUID, 'X-token:' + callState.token
            // ]
            // let options = {
            //   'duration': 160,
            //   'interToneGap': 1200,
            //   'extraHeaders': extraHeaders
            // };

            if (currentSession instanceof Object) {
                if (currentSession.hasAnswer) {
                    currentSession.bye();
                    // currentSession.bye(options);
                } else if (currentSession.isCanceled == false) {
                    currentSession.cancel();
                } else {
                    currentSession.reject();
                }
            }
            const invitation = this.invitation;
            if (!invitation) {
                return;
            } else if (invitation.startTime) {
                // Connected
                invitation.bye();
            } else if (invitation.reject) {
                // Incoming
                invitation.reject();
            } else if (invitation.cancel) {
                // Outbound
                invitation.cancel();
            }
        },

        menuItem(item) {
            this.isHiddenMenu = true;
            this.menuItems = item;
            if (this.debugOpen)
                console.log('layout.vue -> methods -> menuItem()', item);
        },
        showAllClue(val) {
            this.menuList = val;
            this.getCluepool(val, '线索池');
            console.log(this.findAllClue);
            // console.log('dydaydyasud',this.getCluepool(val,'线索池'));
        },
        getCluepool(arr, str) {
            for (let i = 0; i < arr.length; i++) {
                this.findAllClue;
                if (arr[i].name == str) {
                    // return true
                    console.log('sadnasd', arr[i].name);
                    this.findAllClue = true;
                } else {
                    if (arr[i].children && arr[i].children.length > 0) {
                        this.getCluepool(arr[i].children, str);
                    } else {
                        // return false
                    }
                }
            }
        },
        submitForm() {
            this.$refs.question.validate((valid) => {
                // console.log(this.ruleForm.desc)

                console.log(valid);
                if (valid) {
                    console.log('验证通过');

                    askQuestion({
                        content: this.ruleForm.desc,
                    }).then((res) => {
                        // console.log(content);
                        console.log('res', res);
                        if (res.code == 0) {
                            this.dialogVisible = false;
                            this.$message({
                                showClose: true,
                                message: '添加成功',
                                type: 'success',
                            });
                            this.$nextTick(() => {
                                this.ruleForm.desc = '';
                            });
                        } else {
                            this.$message({
                                showClose: true,
                                message: '添加失败',
                                type: 'error',
                            });
                        }
                    });
                }
            });
        },

        handleClose(done) {
            done = false;
        },

        getClueData() {
            clueData().then((res) => {
                if (res.code == 0) {
                    this.clueNum = res.data;
                    setTimeout(() => {
                        this.loading = false;
                    }, 1200);
                } else {
                    this.$message.error(res.message);
                }
            });
        },
        linkNew() {
            let isClick = document
                .getElementById('dragbtn')
                .getAttribute('data-flag');
            if (isClick !== 'true') {
                return false;
            }
            sessionStorage.setItem('path1', 0);
            if (this.$route.path != '/my-clue') {
                console.log(this.menuList);
                this.menuList.forEach((item) => {
                    if (item.name == '销售') {
                        this.menuItems = item;
                    }
                });
                sessionStorage.setItem(
                    'menuItem',
                    JSON.stringify(this.menuItems)
                );
                this.$router.push({
                    path: '/my-clue',
                });

                // this.menuItems = item;
            } else {
                // this.reload();
            }
        },
        toSearch() {
            this.isHiddenMenu = false;
            // sessionStorage.setItem('menuItem','')
            let isClick = document
                .getElementById('dragbtn')
                .getAttribute('data-flag');
            if (isClick !== 'true') {
                return false;
            }
            if (this.$route.path == '/search') {
                return '';
            } else {
                this.menuItems = {};
                this.$router.push('/search');
            }
        },
        closeNewTip() {
            this.getNewClue = false;
            // sessionStorage.getItem("showNewClue", false);
        },
        //websocket连接初始化
        newWebSocket() {
            if (this.debugOpen)
                console.log(
                    'layout.vue -> methods -> newWebSocket()',
                    sessionStorage.getItem('token')
                );
            // let that = this
            if (sessionStorage.getItem('token')) {
                let url = process.env.VUE_APP_URL;
                let urlc = url.replace('http', 'ws');
                const wsuri =
                    urlc +
                    '/websocket/clue?token=' +
                    sessionStorage.getItem('token');
                this.websocket = new WebSocket(wsuri);
                this.websocket.onopen = this.onopen;
                this.websocket.onmessage = this.onmessage;
                this.websocket.onclose = this.onclose;
                this.websocket.onerror = this.onerror;
                window.onbeforeunload = this.close;
            }
        },
        onopen() {
            console.log('WebSocket连接成功');
            this.start();
        },
        onmessage(e) {
            // 做数据处理
            let data = JSON.parse(e.data);
            console.log(data);
            // 获取到的线索条数和线索总数
            if (data.msgType === 'clueCountFloat') {
                // this.resetSetItem("data", JSON.stringify(data.data));
                // this.resetSetItem("clueDataNum", JSON.stringify(data.data));
                this.clueNum = data.data;
            }
            // 获取到的线索数据
            if (data.msgType === 'newCluePush') {
                // 新线索发送
                let newClueGet = data.data;
                this.getNewClue = true;
                console.log(newClueGet, 'gety');
                // 将线索拿下来
                this.$store.commit('changeNewClue', newClueGet);
                // this.resetSetItem("newClueGet", JSON.stringify(newClueGet));
            }
            if (data.msgType === 'clueTimeout') {
                let outClueData = data.data;
                console.log(data.data);
                setTimeout(() => {
                    this.$notify({
                        title: '线索超时',
                        dangerouslyUseHTMLString: true,
                        message:
                            '线索（' +
                            outClueData.name +
                            "）超时已流入<font style='color:#4086EC'>【公共池】</font>",
                    });
                }, 2500);
            }
            if (data.msgType === 'missedCall') {
                this.getMissedList();
                this.isMissedCallHint = true;
            }
            console.log(data.msgType);
        },
        onerror() {
            //错误
            console.log('WebSocket连接发生错误');
            //重连
            this.reconnect();
        },
        //连接关闭事件
        onclose() {
            //关闭
            console.log('WebSocket关闭');
            console.log('connection closed (' + e.code + ')');
            //重连
            this.reconnect();
        },
        // 心跳连接
        start() {
            console.log('开启心跳');
            const self = this;
            let _num = 3;
            self.timeoutObj && clearTimeout(self.timeoutObj);
            self.serverTimeoutObj && clearTimeout(self.serverTimeoutObj);
            self.timeoutObj = setTimeout(function () {
                //这里发送一个心跳，后端收到后，返回一个心跳消息，
                if (self.websocket.readyState === 1) {
                    //如果连接正常
                    // console.log(self.websock);
                    self.websocket.send('ping');
                    // console.log("发送ping");
                } else {
                    //否则重连
                    // self.reconnect();
                }
                self.serverTimeoutObj = setTimeout(function () {
                    //超时关闭
                    _num--;
                    //计算答复的超时次数
                    if (_num === 0) {
                        self.websocketclose();
                    }
                }, self.timeout);
            }, self.timeout);
        },
        //重新连接
        reconnect() {
            let that = this;
            if (that.lockReconnect) {
                return;
            }
            that.lockReconnect = true;
            //没连接上会一直重连，设置延迟避免请求过多
            that.timeoutnum && clearTimeout(that.timeoutnum);
            that.timeoutnum = setTimeout(function () {
                //新连接
                that.newWebSocket();
                that.lockReconnect = false;
            }, 5000);
        },
        toNewCluePage() {
            // console.log(1111);
            sessionStorage.setItem('path1', 0);
            this.$router.push({
                path: '/my-clue',
            });
            console.log(this.$route);
            if (this.$route.path == '/my-clue') {
                console.log(this.$refs.rightView, 'right');
                this.getNewClue = false;
                this.$refs.rightView.selectInfo('', '0');
            }
        },
        //  sip 登陆事件处理
        loginSIPHandle(val) {
            let sipResigter = val;
            this.softPhoneUA = new SIP.UA(sipResigter);
            this.$store.commit('login_calllUA', this.softPhoneUA);
            this.UAEvent(this.softPhoneUA);
            this.softPhoneUA.register({
                // 注册
                register: true,
            });
        },
        //  用户退出登陆事件处理
        logoutHandle(val) {
            window.clearInterval(val);
        },
    },
    // mounted() {
    //   setInterval(() => {
    //     this.num = this.$store.state.num;
    //   }, 1000);
    // },
};
</script>
<style lang="less" scoped>
/deep/ .el-button {
    padding: 8px 15px;
}

/deep/ .el-dialog__title {
    // line-height: 24px;
    font-size: 15px;
    color: #333333;
    //  margin-top: 20px;
    margin-bottom: 20px;
}

/deep/ .el-dialog--center .el-dialog__body {
    text-align: initial;
    padding: 1px 20px 10px;
}

.right {
    width: 100%;
    // overflow-x: scroll;
    overflow: auto;
    height: calc(100vh - 60px);
    // height: 100%;
    background: #f6f6f6;
}

a {
    text-decoration: none;
}

.boxbody-box {
    display: flex;
    overflow: hidden;
}

.newClue {
    cursor: pointer;
    z-index: 1200;
    background-color: #ecf3fd;
    box-shadow: 1px 3px 6px #888888;
    width: 60px;
    height: 60px;
    border: 1px solid #ccc;
    border-radius: 10px;
    color: #4086ec;
    font-size: 14px;

    div:first-child {
        margin-top: 8px;
    }

    div {
        text-align: center;
    }
}

.search {
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    background-color: #ecf3fd;
    box-shadow: 1px 3px 6px #888888;
    width: 60px;
    height: 60px;
    border: 1px solid #ccc;
    border-radius: 10px;
    color: #4086ec;
    font-size: 14px;
}

.bubble {
    position: fixed;
    right: 95px;
    border-radius: 8px 8px 0px 8px;
    vertical-align: middle;
    background-color: #fff;
    box-shadow: 0px 0px 9px #ccc;
    font-size: 14px;
    height: 40px;

    .message {
        margin-left: 5px;
        width: 100%;
        height: 100%;
        display: flex;
        align-items: center;
        padding: 6px;

        img {
            width: 15px;
            height: 15px;
            margin-right: 4px;
        }

        span {
            cursor: pointer;
        }

        i {
            margin-left: 5px;
            margin-top: 3px;
            font-size: 16px;
            margin-right: 5px;
            color: #ccc;
            cursor: pointer;

            &:hover {
                color: #4086ec;
            }
        }
    }
}

.bubble:after {
    content: '';
    position: absolute;
    left: 100%;
    bottom: -10px;
    width: 16px;
    height: 16px;
    border-width: 0;
    border-style: solid;
    border-color: transparent;
    border-top-width: 10px;
    border-top-color: currentColor;
    border-radius: 0 32px 0 0;
    color: #fff;
    filter: drop-shadow(2px 1px 1px #ccc);
}

.search-icon {
    width: 32px;
    height: 32px;
    background-image: url('../assets/common/search.png');
    background-size: 100% 100%;
}

.missed-call-hint {
    position: absolute;
    right: 98px;
    top: 0px;
    width: 247px;
}

.missed-call {
    width: 40px;
    height: 40px;
    background: url('../assets/common/missed_call/Snipaste_2021-12-17_10-32-20.png')
        no-repeat;
    background-size: 80% 75%;
    background-position-x: 6px;
    background-position-y: 6px;
}
.missed-call-dialog {
    width: 260px;
    height: 360px;
    position: absolute;
    right: 120%;
    bottom: 50%;
    z-index: -99;
    border-radius: 13px;
    background-color: #fff;
    box-shadow: 1px 1px 5px #ccc;
}

.missed-call-dialog-title {
    height: 40px;
    padding-top: 10px;
    border-bottom: 2px #ccc solid;
    text-align: center;
    font-size: 14px;
}

.missed-call-dialog-icon {
    position: absolute;
    top: 6px;
    right: 8px;
    cursor: pointer;
}

.missed-call-dialog-list {
    height: 320px;
    overflow: auto;
}

.missed-call-dialog-list-item {
    position: relative;
    background: #f6f9ff;
    padding: 5px 15px;
    margin-top: 10px;
    .head {
        display: flex;
        font-size: 14px;
        padding-bottom: 2px;
        :nth-child(1) {
            flex: 10;
            color: #ababab;
        }
        :nth-child(2) {
            flex: 5;
            color: #7aacf2;
            cursor: pointer;
        }
    }
    .body {
        display: flex;
        padding-bottom: 2px;
        font-size: 16px;
        color: #7aacf2;
        span {
            padding-left: 35px;
        }
    }
    .feet {
        font-size: 14px;
        color: #6e6e6e;
    }
    #clearBtn {
        position: absolute;
        top: 22px;
        right: 2px;
        transform: scale(0.7);
    }
}

.call-phone {
    width: 40px;
    height: 40px;
    background-image: url('../assets/common/call_slices/call-logo2x.png');
    background-size: 120% 120%;
    transform: translate(-4px, -4px);
}

.call-dial {
    width: 280px;
    height: 320px;
    position: absolute;
    right: 120%;
    bottom: 50%;
    z-index: -99;
    // border: 1px solid black;
    border-radius: 13px;
    background-color: #ecf3fd;
    box-shadow: 0.5px 1px 2px #888888;
    display: flex;
    flex-direction: column;
    align-items: center;

    .dial-input {
        width: 83%;
        height: 30px;

        .input-dial-style {
            width: 100%;
            height: 30px;
            font-size: 15px;
            border: 1px solid rgba(220, 223, 230, 1);
            // border: none;
            border-radius: 4px;
            outline: none;

            input::-webkit-input-placeholder {
                color: #e2e2e2;
            }
        }
    }

    .dial-keybord {
        margin-top: 20px;
        width: 83%;
        // border: 1px solid black;
        display: grid;
        grid-template-columns: 33.33% 33.33% 33.33%;
        grid-template-rows: 38px 38px 38px 38px;
        text-align: center;
        // line-height: 30px;
    }

    .dial-keybord > div > span {
        display: block;
        line-height: 38px;
        // border: 1px solid black;
        border: 1px solid #cfcfcf;
        border-bottom: none;
        border-right: none;
    }

    .dial-keybord > div:active {
        background-color: #2299dd;
        color: white;
    }

    .dial-keybord > div:nth-child(1) > span,
    .dial-keybord > div:nth-child(4) > span,
    .dial-keybord > div:nth-child(7) > span,
    .dial-keybord > div:nth-child(10) > span {
        border-left: none;
    }

    .dial-keybord > div:nth-child(1) > span,
    .dial-keybord > div:nth-child(2) > span,
    .dial-keybord > div:nth-child(3) > span {
        border-top: none;
    }
}

.call-dial-close {
    display: flex;
    width: 100%;
    justify-content: flex-end;
}

.call-dial-close > div {
    // width: 30px;
    font-size: 15px;
    margin-top: 10px;
    margin-right: 10px;
    color: rgb(161, 161, 161);
}

.call-button {
    width: 100px;
    height: 32px;
    background-color: rgba(64, 134, 236, 1);
    border-radius: 3px;
    margin-top: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.call-button:hover {
    opacity: 0.5;
}

.call-dial-onPhone {
    width: 184px;
    height: 50px;
    background-color: #ffffff;
    box-shadow: 0px 1px 6px #888888;
    // border: 1px solid black;
    position: absolute;
    right: 110%;
    top: -5%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;

    .onPhone-change {
        display: flex;
        flex-direction: row;
        align-items: center;
    }

    .img-onPhone {
        width: 32px;
        height: 32px;
    }

    .img-onPhone-sec {
        width: 25px;
        height: 25px;
        margin: 0px 10px 0px 10px;
    }
}

.call-out:active {
    opacity: 0.5;
}

/deep/ .tipsDialog {
    .el-dialog__header {
        text-align: center;
        span {
            font-size: 16px;
        }
    }
    .el-dialog__body {
        padding: 10px 10px;
        text-align: center;
        div:nth-child(1) {
            padding-bottom: 20px;
        }
    }
    .el-dialog__footer {
        text-align: center;
    }
}
</style>
