//公共头请求部分设置
const make_public = (data = {}) => {
    return {
        requestDeviceID: '',
        requestTime: Date.now(),
        data: data,
    };
};

export default make_public;
