const mutations = {
    updateState(state, num) {
        if (state.num > 0) {
            state.num = num;
        } else {
            state.num = 0;
        }
    },
    changeNewClue(state, val) {
        state.newClue = val;
        console.log(state.newClue, 'state.newClue');
    },

    //  首页config
    login_callInfor(state, objInfor) {
        // state.sipRegister=JSON.parse(objInfor)
        // console.log(state.sipRegister,'sipReeeeeeee')
        sessionStorage.setItem('config', objInfor);
    },

    // sip UA
    login_calllUA(state, UAInfor) {
        state.callUA = UAInfor;
        // localStorage.setItem('SIP_UA',UAInfor)
        console.log(state.callUA, 'sosssssftPhoneUA');
    },

    // 右侧边拨打电话启用或禁用
    callJudge(state, booleanChange) {
        state.callPhoneChange = booleanChange;
        // console.log(state.callPhoneChange,'state.callPhoneChange')
    },

    // 线索电话拨打禁用
    callButtonClueChange(state, buttonClueBoolean) {
        state.callButtonClueChange = buttonClueBoolean;
        // console.log(state.callButtonClueChange,'asd')
    },

    // 客户线索电话拨打禁用
    callButtonClientChange(state, buttonClientBoolean) {
        state.callButtonClientChange = buttonClientBoolean;
    },

    // 线索和客户的电话参数
    callSatausChange(state, callState) {
        state.callStatus = callState;
    },

    changeWechatState(state, WxState) {
        state.wechatServiceEnabled = WxState;
    },
    // clearCallcallUUID (state) {
    // 	state.callStatus.callUUID = ''
    // }

    // changeTimer_1(state,timer) {
    // 	state.timer_1 = timer
    // },

    // changeTimer_2(state,timer) {
    // 	state.timer_2 = timer
    // }

    // 更改呼叫状态
    changePhoneState(state, isBeingPhone) {
        state.isBeingPhone = isBeingPhone;
    },
    changeFollowData(state, followData) {
        state.followData = followData;
    },
};
export default mutations;
