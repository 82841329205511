import CryptoJS from 'crypto-js';
export default {
    allValueNeed(code) {
        let allValue = JSON.parse(sessionStorage.getItem('dictionaries'));
        let info = {};
        if (Array.isArray(allValue)) {
            allValue.forEach((item) => {
                if (code == item.code) {
                    info = item.values;
                }
            });
        }
        return info;
    },
    sigelValueNeed(code, arr) {
        let info = {};
        arr.forEach((item) => {
            if (code == item.code) {
                info = item.values;
            }
        });
        return info;
    },
    InitTime(endtime) {
        //首次进入页面显示的时间数据
        let dd,
            hh,
            mm,
            ss = null;
        let time = parseInt(endtime) - new Date().getTime() / 1000;
        if (time <= 0) {
            return '0秒';
        } else {
            dd = Math.floor(time / 60 / 60 / 24);
            hh = Math.floor((time / 60 / 60) % 24);
            mm = Math.floor((time / 60) % 60);
            ss = Math.floor(time % 60);
            let str = (dd ? dd + '天' : '') + (hh ? hh + '小时' : '') + (mm ? mm + '分' : '') + ss + '秒';
            return str;
        }
    },
    // 获取路由参数 输入想要的值
    getQueryString(name, search) {
        search = search || window.location.search.substr(1) || window.location.hash.split('?')[1];
        let reg = new RegExp('(^|&)' + name + '=([^&]*)(&|$)');
        let r = search ? search.match(reg) : '';
        if (r != null) return unescape(r[2]);
        return null;
    },
    // 数据处理
    getTreeData(data) {
        // 循环遍历json数据
        for (let i = 0; i < data.length; i++) {
            if (data[i].children.length < 1) {
                // children若为空数组，则将children设为undefined
                data[i].children = undefined;
            } else {
                // children若不为空数组，则继续 递归调用 本方法
                this.getTreeData(data[i].children);
            }
        }
        return data;
    },
    // 隐藏手机号
    hidePhoneNumber(data, wx) {
        if (!data) {
            if (this.debugOpen) console.log('common.js -> hidePhoneNumber()', '手机号加密有误');
            return;
        }
        const setInfo = JSON.parse(window.sessionStorage.getItem('config'));
        let phoneNumber = Decrypt(data, setInfo.username);
        if (wx) return phoneNumber;
        if (setInfo.phoneNumberHideEnabled) {
            return hide(phoneNumber);
        } else {
            return phoneNumber;
        }
    },
};

// 隐藏手机号
function hide(number) {
    let hiddenPhoneNumber = '';
    for (let i = 0; i < number.length; i++) {
        if (i > 2 && i < 7) {
            hiddenPhoneNumber += '*';
        } else {
            hiddenPhoneNumber += number[i];
        }
    }
    return hiddenPhoneNumber;
}

// 解密方法
function Decrypt(encode, key) {
    let md5Key = CryptoJS.MD5(key)
        .toString()
        .substr(0, 16);
    let cryptKey = CryptoJS.enc.Utf8.parse(md5Key);
    let deconde = CryptoJS.AES.decrypt(encode, cryptKey, {
        mode: CryptoJS.mode.ECB,
        padding: CryptoJS.pad.NoPadding,
    });
    let phoneNumber = deconde.toString(CryptoJS.enc.Utf8).substring(0, 11);
    return phoneNumber;
}
