import request from './request.js';
import public_request from './public_request';

export function getCallSetting() {
    return request({
        url: '/setting/call',
        method: 'GET',
        params: public_request(),
    });
}

export function reviseCallSetting(data) {
    return request({
        url: '/setting/call',
        method: 'PUT',
        data: public_request(data),
    });
}

export function getCallState(phoneNum) {
    return request({
        url: '/common/phone-number/status',
        method: 'GET',
        params: public_request({
            phoneNum,
        }),
    });
}

export function getMissedList() {
    return request({
        url: '/common/missed-call-list',
        method: 'GET',
        params: public_request({}),
    });
}
