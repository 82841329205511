import request from './request.js';
import public_request from './public_request';

//通话记录
export function getInvalidClueList(data) {
    return request({
        url: '/clue-pool/invalid/clues',
        method: 'GET',
        params: public_request(data),
    });
}

export function getInvalidClueInfo(clueId) {
    return request({
        url: '/clue-pool/invalid/clue',
        method: 'GET',
        params: public_request({ clueId }),
    });
}

export function recoveryClues(data) {
    return request({
        url: '/clue-pool/invalid/clues-status-valid',
        method: 'PUt',
        data: public_request(data),
    });
}

export function clearRecord(id) {
    return request({
        url: '/common/missed-call',
        method: 'DELETE',
        data: public_request({ id }),
    });
}
