const formValidation = {
    //手机号
    mobile: (rule, value, callback) => {
        if (!value) {
            callback(new Error('手机号不能为空'));
        } else {
            const reg = /^1[3|4|5|6|7|8][0-9]\d{8}$/;
            if (reg.test(value)) {
                callback();
            } else {
                callback(new Error('请输入正确的手机号'));
            }
        }
    },
    //0-127
    ldq: (rule, value, callback) => {
        if (value === '') {
            callback();
        } else {
            if (value < 0 || value > 127) {
                callback(new Error('有效值范围是0-127'));
            } else {
                callback();
            }
        }
    },
    //0-10000
    ldyw: (rule, value, callback) => {
        if (value === '') {
            callback();
        } else {
            if (value < 0 || value > 10000) {
                callback(new Error('有效值范围是0-10000'));
            } else {
                callback();
            }
        }
    },
    //0-100000
    ldsw: (rule, value, callback) => {
        if (value === '') {
            callback();
        } else {
            if (value < 0 || value > 100000) {
                callback(new Error('有效值范围是0-100000'));
            } else {
                callback();
            }
        }
    },
    //0-100000000
    ldyy: (rule, value, callback) => {
        if (value === '') {
            callback();
        } else {
            if (value < 0 || value > 100000000) {
                callback(new Error('有效值范围是0-100000000'));
            } else {
                callback();
            }
        }
    },
    //姓名
    name: (rule, value, callback) => {
        let reg = /^[a-zA-Z0-9\u4e00-\u9fa5]*$/g;
        if (value === '') {
            callback();
        } else {
            if (!reg.test(value)) {
                callback(new Error('格式不正确'));
            } else {
                callback();
            }
        }
    },
    //名称
    heading: (rule, value, callback) => {
        // let reg = /^([\u4E00-\u9FA5A-Za-z])([\u4E00-\u9FA5A-Za-z0-9-_\s])+$/g;
        let reg = /[\u4e00-\u9fa5_a-zA-Z]{1,32}/;
        if (!reg.test(value)) {
            callback(new Error('请输入正确的名称格式'));
        } else {
            callback();
        }
    },
    //用户名
    username: (rule, value, callback) => {
        let reg = /^[a-zA-Z]{1}([a-zA-Z0-9]|[_-]){5,19}$/;
        if (!reg.test(value)) {
            callback(new Error('格式不正确'));
        } else {
            callback();
        }
    },
    //登陆账号前缀
    prefix: (rule, value, callback) => {
        let reg = /^[a-zA-Z]{1}([a-zA-Z0-9]|[_-]){0,10}$/;
        if (!reg.test(value)) {
            callback(new Error('格式不正确'));
        } else {
            callback();
        }
    },
    //5-720
    wdqrl: (rule, value, callback) => {
        if (value < 5 || value > 720) {
            callback(new Error('有效值范围是5-720'));
        } else {
            callback();
        }
    },
    //1-127
    ydyeq: (rule, value, callback) => {
        if (value < 1 || value > 127) {
            callback(new Error('有效值范围是1-127'));
        } else {
            callback();
        }
    },
};
export default formValidation;
