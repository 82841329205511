import request from './request.js';
import public_request from './public_request';

// 标记为无效客户（我的客户）
export function InvalidTagClient(data) {
    return request({
        url: '/client-management/my/client-status-invalid',
        method: 'PUT',
        data: public_request(data),
    });
}

export function getInvalidClientList(data) {
    return request({
        url: '/client-management/invalid/clients',
        method: 'GET',
        params: public_request(data),
    });
}

export function getInvalidClientInfo(clientId) {
    return request({
        url: '/client-management/invalid/client',
        method: 'GET',
        params: public_request({ clientId }),
    });
}

export function recoveryClients(data) {
    return request({
        url: '/client-management/invalid/clients-status-valid',
        method: 'PUt',
        data: public_request(data),
    });
}
