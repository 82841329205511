// 将时间戳修改为时间格式
let getYMDHMS = (timestamp) => {
    let ex = timestamp.toString();
    if (ex.indexOf('-') != -1) {
        return timestamp;
    } else {
        let time = new Date(timestamp * 1000);
        let year = time.getFullYear();
        const month = (time.getMonth() + 1).toString().padStart(2, '0');
        const date = time
            .getDate()
            .toString()
            .padStart(2, '0');
        const hours = time
            .getHours()
            .toString()
            .padStart(2, '0');
        const minute = time
            .getMinutes()
            .toString()
            .padStart(2, '0');
        const second = time
            .getSeconds()
            .toString()
            .padStart(2, '0');
        return year + '-' + month + '-' + date + ' ' + hours + ':' + minute + ':' + second;
    }
};
// 将时间戳修改为时间格式
let getYMDHMSNh = (timestamp) => {
    let time = new Date(timestamp * 1000);
    let year = time.getFullYear();
    const month = (time.getMonth() + 1).toString().padStart(2, '0');
    const date = time
        .getDate()
        .toString()
        .padStart(2, '0');
    return year + '-' + month + '-' + date;
};

let tranNumber = (num) => {
    let numStr = num.toString();
    // 十万以内直接返回
    if (numStr.length < 6) {
        return numStr;
    }
    //大于8位数是亿
    else if (numStr.length > 8) {
        let decimal = numStr.substring(numStr.length - 8, numStr.length - 8);
        return parseFloat(parseInt(num / 100000000) + '.' + decimal) + '亿';
    }
    //大于6位数是十万 (以10W分割 10W以下全部显示)
    else if (numStr.length > 5) {
        let decimal = numStr.substring(numStr.length - 4, numStr.length - 4);
        return parseFloat(parseInt(num / 10000) + '.' + decimal) + '万';
    }
};

export default {
    getYMDHMS,
    getYMDHMSNh,
    tranNumber,
};
