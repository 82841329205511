import axios from 'axios';
import public_request from './public_request';
//创建axios的一个实例
let baseURL = process.env.VUE_APP_URL;

let instance = axios.create({
    baseURL: baseURL, //接口统一域名
    timeout: 15000, //设置超时
});
instance.defaults.headers['Content-Type'] = 'application/json;charset=UTF-8';
//------------------- 一、请求拦截器 忽略
instance.interceptors.request.use(
    function(config) {
        config.headers['authorization'] = sessionStorage.getItem('token');
        return config;
    },
    function(error) {
        // 对请求错误做些什么
        return Promise.reject(error);
    }
);

//----------------- 二、响应拦截器 忽略
instance.interceptors.response.use(
    function(response) {
        return response.data;
    },
    function(error) {
        // 对响应错误做点什么
        const response = error.response;
        if (response.config.url != '/open/session' && sessionStorage.getItem('token')) {
            if (error.response) {
                switch (error.response.status) {
                    case 401:
                        console.log('token 过期');
                        // debugger
                        refreshX();
                        return;
                    case 417:
                        console.log('token 过期');
                        // debugger
                        refreshX();
                        return;
                }
            }
        } else {
            return response.data;
        }
        // return Promise.reject(error.response);
    }
);

function refreshX() {
    let refreshToken = sessionStorage.getItem('refreshToken');
    axios({
        method: 'put',
        url: baseURL + '/open/session',
        data: public_request({
            refreshToken: refreshToken,
        }),
        headers: {
            Authorization: sessionStorage.getItem('token'),
        },
    })
        .then((result) => {
            console.log('result', result);
            sessionStorage.setItem('token', result.data.data.token);
            sessionStorage.setItem('refreshToken', result.data.data.refreshToken);
            sessionStorage.setItem('code', result.data.code);
            location.reload();
        })
        .catch((err) => {
            console.log('真的过期了');
            sessionStorage.setItem('code', err.response.data.code);
            // location.reload();
        });
}
export default instance;
