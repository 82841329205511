/*
 防止layout文件过大
*/
// import ClueInfoDialog from '@/components/ClueInfoDialog';
// import RestoreDialog from '@/components/RestoreDialog';
// import ClientInfoDialog from '@/components/ClientInfoDialog';
import { recoveryClues, clearRecord } from '@/api/invalid-clue';
import { recoveryClients } from '@/api/invalid-client';
export default {
    data() {
        return {
            restoreDialog: false,
            optional: [],
            titlt: '',
            ids: [],
        };
    },
    methods: {
        clearBtn(item) {
            clearRecord(item.id).then((res) => {
                if (res.code !== '0') return;
                this.getMissedList();
            });
        },
        // 添加客户
        addNewClient(item) {
            if (this.debugOpen) console.log('layout.js -> methods -> addNewClient()');
            switch (item.type) {
                case 0:
                    this.showAddDialog = true;
                    this.$refs.addClient.clientForm.mobileNumber = this.common.hidePhoneNumber(item.phoneNum, 1);
                    break;
                case 1:
                    this.toClue(item);
                    break;
                case 2:
                    this.toClient(item);
                    break;
                case 3:
                    this.toInvalidClue(item);
                    break;
                case 4:
                    this.toInvalidClient(item);
                    break;
                default:
                    break;
            }
        },
        toClue(item) {
            this.$route.fullPath === '/my-clue' ? this.event({ id: item.clueId }) : this.push('/my-clue', { id: item.clueId });
        },
        toClient(item) {
            this.$route.fullPath === '/my-client' ? this.event({ id: item.clientId }) : this.push('/my-client', { id: item.clientId });
        },
        toInvalidClue(item) {
            this.type = 1;
            this.optional = ['超时时间', '导入位置'];
            this.ids = [item.clueId];
            this.$refs.clueShowDialog.init(item.clueId, true);
        },
        toInvalidClient(item) {
            this.type = 0;
            this.optional = ['导入位置'];
            this.ids = [item.clientId];
            this.$refs.clientShowDialog.init(item.clientId, true);
        },

        push(path, data) {
            this.menuItems = this.menuList[1];
            this.$nextTick(() => {
                this.$store.commit('changeFollowData', data);
                this.$router.push(path);
            });
        },
        event(data) {
            this.$EventBus.$emit('showDialog', data);
        },

        // 恢复
        recovers() {
            this.restoreDialog = true;
        },
        // 确定恢复
        confirm(data) {
            this.type === 1
                ? this.recoveryClues({
                      ...data,
                      ids: this.ids,
                  })
                : this.recoveryClients({
                      ...data,
                      ids: this.ids,
                  });
        },
        async recoveryClues(val) {
            const { code, message } = await recoveryClues(val);
            if (code !== '0') return console.log(message);
        },
        async recoveryClients(val) {
            const { code, message } = await recoveryClients(val);
            if (code !== '0') return console.log(message);
        },
    },
    components: {
        // RestoreDialog,
        // ClueInfoDialog,
        // ClientInfoDialog,
    },
};
