import Vue from 'vue';
import App from './App.vue';
import ElementUI from 'element-ui';
import router from './router';
import 'element-ui/lib/theme-chalk/index.css';
import NProgress from 'nprogress';
import 'nprogress/nprogress.css';
import store from './store/index';
import axios from 'axios';
import echarts from 'echarts';
import './assets/iconfont/iconfont.css';
import './assets/department-icon/iconfont.css';
import formValidation from '../src/assets/js/regular'; //导入js所在文件夹
import filter from './plugins/filter';
import common from './plugins/common'; //公共方法
import Plugin from 'v-fit-columns'; //自适应table
import elTableInfiniteScroll from 'el-table-infinite-scroll'; //el-table-infinite-scroll下拉引入
import callServices from '@/assets/js/callServices';

Vue.use(elTableInfiniteScroll);
Vue.use(Plugin);
import moment from 'moment'; //导入模块
moment.locale('zh-cn'); //设置语言 或 moment.lang('zh-cn');
Vue.prototype.$moment = moment; //赋值使用
Object.keys(filter).forEach((key) => Vue.filter(key, filter[key]));

Vue.prototype.$EventBus = new Vue();
Vue.prototype.debugOpen = true;

Vue.prototype.formValidation = formValidation; //挂载到原型上
Vue.prototype.$echarts = echarts;
Vue.prototype.$axios = axios;
Vue.prototype.$dictionary = store.state.dictionary;
Vue.prototype.common = common; //使用公共方法
Vue.prototype.callServices = callServices;

//用来监听是否过来了新数据
Vue.prototype.resetSetItem = function(key, newVal) {
    // 创建一个StorageEvent事件
    let newStorageEvent = document.createEvent('StorageEvent');
    const storage = {
        setItem: function(k, val) {
            sessionStorage.setItem(k, val);
            // 初始化创建的事件
            newStorageEvent.initStorageEvent('setItem', false, false, k, null, val, null, null);
            // 派发对象
            window.dispatchEvent(newStorageEvent);
        },
    };
    // 根据判断获取session中存入的多个值
    if (key === 'data') {
        return storage.setItem(key, newVal);
    } else if (key === 'newClueGet') {
        return storage.setItem(key, newVal);
    } else if (key === 'allValue') {
        return storage.setItem(key, newVal);
    }
};
Vue.use(ElementUI);
Vue.config.productionTip = false;
NProgress.configure({
    easing: 'ease', // 动画方式
    speed: 500, // 递增进度条的速度
    showSpinner: false, // 是否显示加载ico
    trickleSpeed: 200, // 自动递增间隔
    minimum: 0.3, // 初始化时的最小百分比
});
router.beforeEach((to, from, next) => {
    NProgress.start();
    NProgress.inc();
    let code = sessionStorage.getItem('code');
    //判断此用户是否有权限来访问路由
    //控制用户在路由的操作
    if (to.path == '/404') {
        next();
    } else if (to.path == '/401') {
        next();
    } else {
        if (code == '0' && to.path == '/login') {
            next('');
        } else if (code == '0' && to.path != '/login') {
            setTimeout(() => {
                next();
            }, 100);
        } else if (code != '0' && to.path == '/login') {
            next();
        } else if (code != '0' && to.path != '/login') {
            next('./login');
        }
    }
});
//当路由进入后：关闭进度条
router.afterEach(() => {
    NProgress.done();
});
// 拖动
Vue.directive('drag', {
    inserted: function(el) {
        let firstTime = '',
            lastTime = '';
        el.onmousedown = function(ev) {
            document.getElementById('dragbtn').setAttribute('data-flag', false);
            firstTime = new Date().getTime();
            var disY = ev.clientY - el.offsetTop;
            document.onmousemove = function(ev) {
                var t = ev.clientY - disY;
                if (t <= 0) {
                    t = 0;
                } else if (t >= document.documentElement.clientHeight - el.offsetHeight) {
                    t = document.documentElement.clientHeight - el.offsetHeight;
                }
                el.style.top = t + 'px';
            };
            document.onmouseup = function() {
                document.onmousemove = null;
                document.onmouseup = null;
                lastTime = new Date().getTime();
                if (lastTime - firstTime < 200) {
                    document.getElementById('dragbtn').setAttribute('data-flag', true);
                }
            };
        };
    },
});

new Vue({
    render: (h) => h(App),
    router,
    store,
}).$mount('#app');
