import request from './request.js';
import public_request from './public_request';
//登录获取token
export function login({ username, password, verificationCode }) {
    return request({
        url: '/open/session',
        method: 'post',
        data: public_request({
            username,
            password,
            verificationCode,
        }),
    });
}
//生成验证码
export function yzm({ loginName }) {
    return request({
        url: '/open/login-verification-code',
        method: 'GET',
        params: public_request({
            loginName,
        }),
    });
}
//刷新token
export function refresh({ refreshToken }) {
    return request({
        url: '/open/session',
        method: 'put',
        data: public_request({
            refreshToken,
        }),
    });
}
//退出
export function logout() {
    return request({
        url: '/open/session',
        method: 'DELETE',
        data: public_request(),
    });
}
