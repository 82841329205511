<template>
    <div>
        <div v-if="routerPath != '/home'" class="box">
            <div class="tac1">
                <el-menu
                    :default-active="activeOpen"
                    :unique-opened="true"
                    class="el-menu-vertical-demo"
                    text-color="#fff"
                    active-text-color="#fff"
                    :router="true"
                >
                    <div v-for="(item, index) in menusItemList.children" :key="index">
                        <el-submenu v-if="item.children.length > 0" :index="item.name">
                            <template slot="title">
                                <i :class="item.icon" class="iconfont" />
                                <span>{{ item.name }}</span>
                            </template>
                            <div v-for="(sonitem, i) in item.children" :key="index + i">
                                <el-menu-item :index="'/' + sonitem.linkPageCode">
                                    <i :class="sonitem.icon" class="iconfont" />
                                    <span>{{ sonitem.name }}</span>
                                </el-menu-item>
                            </div>
                        </el-submenu>
                        <el-menu-item
                            v-if="item.children.length == 0"
                            :index="'/' + item.linkPageCode"
                        >
                            <i :class="item.icon" class="iconfont" />
                            <span slot="title">{{ item.name }}</span>
                        </el-menu-item>
                    </div>
                </el-menu>
            </div>
        </div>
    </div>
</template>
<script>
// import { menus } from "../api/public_content";
export default {
    props: {
        menuItems: {
            type: Object,
            default: () => {},
        },
    },
    data() {
        return {
            menusItemList: {},
            routerPath: '',
            activeOpen: '',
        };
    },
    watch: {
        $route: {
            handler(newRouter) {
                this.routerPath = newRouter.path;
                if (this.debugOpen) console.log('sidebar.vue -> watch -> $route', newRouter.path);
                // this.routerCode = linkPageCode;
            },
            immediate: true,
        },
        menuItems(val) {
            if (this.debugOpen) console.log('sidebar.vue -> watch -> menuItems()', val);
            // this.activeOpen = this.$route.path;
            this.menusItemList = val;
            // console.log(this.menusItemList, 'd')
            if (val.linkPageCode) {
                this.$router.push(val.linkPageCode);
                if (val.linkPageCode != 'home') {
                    // this.$nextTick(() => {
                    //   this.activeOpen = "/" + val.linkPageCode;
                    // });
                }
            } else if (val.linkPageCode == '' && val.children) {
                if (!val.children[0].linkPageCode) {
                    this.$router.push(val.children[0].children[0].linkPageCode);
                    // this.$nextTick(() => {
                    //   this.activeOpen = "/" + val.children[0].children[0].linkPageCode;
                    // });
                } else {
                    this.$router.push(val.children[0].linkPageCode);
                    // this.$nextTick(() => {
                    //   this.activeOpen = "/" + val.children[0].linkPageCode;
                    // });
                }

                // this.activeOpen = "";
                // this.$nextTick(() => {
                //   this.activeOpen = "/" + val.children[0].linkPageCode;
                // });
            } else {
                return false;
            }
        },
    },
    computed: {
        //控制当前菜单栏的选择状态
        // open1() {
        //   console.log(111)
        //   return this.$route.path;
        // },
    },
    created() {
        if (this.debugOpen) console.log('sidebar.vue -> created()');
    },
    mounted() {
        if (this.debugOpen) console.log('sidebar.vue -> mounted()');

        //获取菜单列表
        // menus({}).then((res) => {
        //   if (res.code == 0) {
        //     this.menusItemList = res.data.list;
        //   } else {
        //     this.$message.error(res.error);
        //   }
        // });
    },
};
</script>
<style lang="less" scoped>
* {
    padding: 0;
}

a {
    text-decoration: none;
}

.box {
    height: 100%;
}

.tac1 {
    width: 180px;
    text-align: left;
    padding-top: 30px;
    height: calc(100vh - 60px);
    overflow-y: none;
    overflow-x: none;
    // height: 100%;
    background: linear-gradient(to right, #0bbafb, #4285ec) !important;
}

::v-deep .el-submenu .el-menu-item {
    min-width: 180px;
}

::v-deep .el-menu-item i {
    color: white !important;
}

::v-deep .el-menu {
    border-right: none !important;

    i {
        margin-right: 5px;
    }
}

::v-deep .el-menu,
::v-deep .el-menu-item,
::v-deep .el-menu-item:hover,
::v-deep .el-submenu__title:hover {
    background-color: transparent !important;
}

::v-deep .el-submenu__title i {
    color: white !important;
    font-size: 16px;
}

::v-deep .el-menu-item.is-active {
    background: #4086ec !important;
}

::v-deep .el-submenu__icon-arrow {
    transform: rotate(-90deg);
}

::v-deep .el-submenu.is-opened > .el-submenu__title .el-submenu__icon-arrow {
    transform: rotate(0deg) !important;
}

::v-deep .el-menu {
    overflow: hidden;
}
</style>
