import Vue from 'vue';
import Vuex from 'vuex';
import mutations from './mutations';
import actions from './actions';
Vue.use(Vuex);
export default new Vuex.Store({
    //state存放所有共享数据
    state: {
        wechatServiceEnabled: false,
        num: 18,
        dictionary: {},
        newClue: {},
        string: 0,
        messageString: [],
        callButtonClueChange: true,
        callButtonClientChange: undefined,
        callStatus: {
            phoneNumber: null,
            clueId: null,
            clientId: null,
            callUUID: null,
            // followSaved:'',
            calledName: null,
            token: null,
        },
        todos: [
            {
                id: 1,
                text: '...',
                done: true,
            },
            {
                id: 2,
                text: '...',
                done: false,
            },
        ],
        callPhoneChange: undefined,
        callUA: {},
        // timer_1:null,
        // timer_2:null,
        sipRegister: JSON.parse(sessionStorage.getItem('config')), //账号名
        // sipRegister: {
        // 	// uri: 'sip:webrtc@test.cti.hzrjj.com',
        // 	// wsServers: 'wss://test.cti.hzrjj.com:7443',
        // 	// authorizationUser: 'webrtc', // 授权号
        // 	// displayName: 'webrtc', // 显示名称
        // 	// password: '888888', // 登陆密码
        // 	// allowLegacyNotifications: true,
        // 	// autostart: true, // 自动连接
        // 	// register: false, // 自动就绪
        // 	// rel100: SIP.C.supported.SUPPORTED, // 该选项开启接收早期媒体(采坑记录)

        // 	// uri: '',
        // 	// wsServers: '',
        // 	// authorizationUser: '', // 授权号
        // 	// displayName: '', // 显示名称
        // 	// password: '', // 登陆密码
        // 	// allowLegacyNotifications:null,
        // 	// autostart: null, // 自动连接
        // 	// // register: undefined, // 自动就绪
        // 	// rel100: undefined, // 该选项开启接收早期媒体(采坑记录)
        // }

        // 是否正在打电话
        isBeingPhone: false,
        followData: null,
    },
    //状态的变化
    mutations,
    actions,
    getters: {},
});
