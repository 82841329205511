<template>
    <div id="app">
        <router-view v-if="isRouterAlive" />
    </div>
</template>

<script>
// import { all_value } from "./api/public_content";
export default {
    provide() {
        //父组件中通过provide来提供变量，在子组件中通过inject来注入变量。
        return {
            reload: this.reload,
        };
    },
    data() {
        return {
            isRouterAlive: true,
            newArrLength: 0,
            websocket: null,
            lockReconnect: false, //是否真正建立连接
            // timeout: 9 * 60 * 1000, //30秒一次心跳
            timeout: 1000, //30秒一次心跳
            timeoutObj: null, //心跳心跳倒计时
            timeoutnum: null, //断开 重连倒计时
            websocketConnectedCount: 0,
            sessionInfo: '',
        };
    },
    mounted() {},
    watch: {},
    methods: {
        reload() {
            this.isRouterAlive = false; //先关闭，
            this.$nextTick(function () {
                this.isRouterAlive = true; //再打开
            });
        },
    },
};
</script>

<style lang="less">
#app {
    font-family: Avenir, Helvetica, Arial, sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    /* text-align: center; */
    color: #2c3e50;
    min-width: 1345px;
}

.el-popover {
    max-height: 60vh !important;
    // overflow: auto;
    // overflow-x: hidden !important;
    // overflow: hidden;
}

&::-webkit-scrollbar {
    width: 6px;
    height: 6px;
    background: transparent;
}

&::-webkit-scrollbar-thumb {
    background: transparent;
    border-radius: 4px;
}

&:hover::-webkit-scrollbar-thumb {
    background: hsla(0, 0%, 53%, 0.4);
}

&:hover::-webkit-scrollbar-track {
    background: hsla(0, 0%, 53%, 0.1);
}

.el-dialog {
    display: flex;
    flex-direction: column;
    margin: 0 !important;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    max-height: calc(100% - 30px);
    max-width: calc(100% - 30px);
    border-radius: 10px !important;
}

.el-dialog .el-dialog__body {
    flex: 1;
    overflow: auto;
}

.el-popover {
    padding: 20px !important;

    /deep/ .el-popconfirm__main {
        margin-bottom: 30px;
    }

    /deep/ .el-button:first-child {
        float: right;
        margin-left: 10px;
    }
}
</style>
