import request from './request.js';
import public_request from './public_request';
//获取菜单
export function menus() {
    return request({
        url: '/common/menus',
        method: 'get',
        params: public_request(),
    });
}
//按字典代码获取字典中文值列表
export function c_values({ dictionaryCode }) {
    return request({
        url: '/common/dictionary/code/values',
        method: 'get',
        params: public_request({
            dictionaryCode,
        }),
    });
}
// 获取全部的字典值
export function all_value() {
    return request({
        url: '/common/dictionaries',
        method: 'get',
        params: public_request(),
    });
}

//获取页面元素
export function elements({ pageCode }) {
    return request({
        url: '/common/page/elements',
        method: 'get',
        params: public_request({
            pageCode,
        }),
    });
}
//获取登录用户信息
export function login_user() {
    return request({
        url: '/common/login-user',
        method: 'get',
        params: public_request(),
    });
}
//修改登录用户密码
export function password({ oldPassword, newPassword }) {
    return request({
        url: '/common/login-user/password',
        method: 'PUT',
        data: public_request({
            oldPassword,
            newPassword,
        }),
    });
}
//获取部门列表（包含成员）
export function departments_staff() {
    return request({
        url: '/common/departments-staff',
        method: 'get',
        params: public_request(),
    });
}

//获取部门列表
export function departments() {
    return request({
        url: '/common/departments',
        method: 'get',
        params: public_request(),
    });
}

//获取部门员工列表
export function staff({ departmentId }) {
    return request({
        url: '/common/department/staff',
        method: 'get',
        params: public_request({
            departmentId,
        }),
    });
}

// 获取公司池公司海
export function companySea() {
    return request({
        url: '/common/departments-has-pool-sea',
        method: 'get',
        params: public_request(),
    });
}
// 获取列显隐
export function columnShow(data) {
    return request({
        url: '/common/columns-show',
        method: 'get',
        params: public_request(data),
    });
}

// 修改列显隐
export function showOrHiddenCol(data) {
    return request({
        url: '/common/columns-show',
        method: 'PUT',
        data: public_request(data),
    });
}

// 行政区划
export function areas(data) {
    return request({
        url: '/common/areas',
        method: 'get',
        params: public_request(data),
    });
}

// 获取组织机构登录界面信息
export function styleInfo(data) {
    return request({
        url: '/open/style-info',
        method: 'get',
        params: public_request(data),
    });
}
// 刚进入的时候查看线索数量
export function clueData() {
    return request({
        url: '/common/float-icon-data',
        method: 'get',
        params: public_request(),
    });
}

//提交问题
export function askQuestion(data) {
    return request({
        url: '/common/feedback',
        method: 'POST',
        data: public_request(data),
    });
}
